import { useState, useRef, useEffect, useCallback } from 'react';
import Swiper from 'react-id-swiper';
import SwiperCore, { Pagination, Autoplay, Mousewheel, Scrollbar } from 'swiper';
import 'swiper/swiper.min.css';
import MobileTouchArea from './mobile-touch-area';

SwiperCore.use([Pagination, Autoplay, Mousewheel, Scrollbar]);

const Arrow = ({ direction = 'left' }) => (
  <div
    className={`h-[17.5%] w-[17.5%] border-r border-t border-current
    ${direction === 'right' ? 'ml-[-7.5%] rotate-[45deg]' : 'mr-[-7.5%] rotate-[-135deg]'}`}
  />
);

const ManipulatingSwiper = ({
  children,
  className = '',
  slidesPerView = 1.2,
  spaceBetween = 0,
  slidesOffsetBefore = 15,
  slidesOffsetAfter = 15,
  wrapperClass = 'swiper-wrapper',
  darkButtons = false,
  hideButtons = false,
  moveButtons = false,
  fitGuideButtons = false,
  centeredSlides = false,
  showBullets = false,
  autoplay = false,
  loop = false,
  freeMode = false,
  mousewheel = false,
  scrollbar = {},
  breakpoints = {},
  onSlideChange,
  initialSlide = 0,
  threshold = 5,
  slideToClickedSlide = true,
}) => {
  const ref = useRef(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [activeIdx, setActiveIdx] = useState(0);
  const [swiper, setSwiper] = useState();

  useEffect(() => {
    if (!swiper && ref.current?.swiper) setSwiper(ref.current?.swiper);
  }, [ref, swiper]);

  const slideCount = children.length;
  const has2PagesOrLess = slidesPerView >= slideCount / 2;
  const lastIdx = slideCount - 1;

  const goPrev = () => (has2PagesOrLess ? swiper?.slideTo(0) : swiper?.slidePrev());
  const goNext = () => (has2PagesOrLess ? swiper?.slideTo(lastIdx) : swiper?.slideNext());

  useEffect(() => {
    if (swiper) {
      const slideChangeHandler = () => {
        setIsBeginning(swiper.isBeginning);
        setIsEnd(swiper.slides.length <= swiper.params.slidesPerView ? true : swiper.isEnd);
        setActiveIdx(swiper.realIndex);
      };
      const reachBeginningHandler = () => setIsBeginning(true);
      const reachEndHandler = () => setIsEnd(true);
      const resetHandler = () => {
        swiper.slideTo(0, 0);
        slideChangeHandler();
      };

      // Center slides if total width is less than swiper width
      const totalSlidesWidth = swiper.slidesSizesGrid.reduce((acc, curr) => acc + curr, 0);
      const swiperWrapper = swiper.el.querySelector('.swiper-wrapper');
      swiperWrapper.classList[swiper.size > totalSlidesWidth ? 'add' : 'remove']('center-slides');

      swiper.on('slideChange', slideChangeHandler);
      swiper.on('reachBeginning', reachBeginningHandler);
      swiper.on('reachEnd', reachEndHandler);
      swiper.on('observerUpdate', resetHandler);
      swiper.on('resize', instance => {
        const currentTotalSlidesWidth = swiper.slidesSizesGrid.reduce((acc, curr) => acc + curr, 0);
        const currentSwiperWrapper = swiper.el.querySelector('.swiper-wrapper');

        currentSwiperWrapper.classList[instance.size > currentTotalSlidesWidth ? 'add' : 'remove'](
          'center-slides'
        );
      });

      if (onSlideChange) swiper.on('slideChange', onSlideChange);

      return () => {
        swiper.off('slideChange', slideChangeHandler);
        swiper.off('reachBeginning', reachBeginningHandler);
        swiper.off('reachEnd', reachEndHandler);
        swiper.off('observerUpdate', resetHandler);

        if (onSlideChange) swiper.off('slideChange', onSlideChange);
      };
    }

    return () => {};
  }, [onSlideChange, swiper]);

  const SwiperButton = useCallback(
    ({ className: btnClassName, children: btnChildren, onClick }) => {
      const getPositionClassName = () => {
        if (moveButtons) return 'top-[0%] md:top-[50%]';
        if (fitGuideButtons) return 'top-[25%] mx-[8px] md:mx-0 md:top-[30%]';
        return 'top-[50%] md:top-[50%]';
      };

      return (
        <button
          type="button"
          className={`absolute z-10 flex h-[24px] w-[24px] translate-y-[-100%] cursor-pointer items-center justify-center rounded-[50%] md:h-[32px] md:w-[32px] 
        ${getPositionClassName()}
        ${darkButtons ? 'bg-blue text-white' : 'bg-white text-blue'} 
        ${btnClassName}`}
          onClick={onClick}
          aria-hidden="true"
        >
          <MobileTouchArea />
          {btnChildren}
        </button>
      );
    },
    [darkButtons, fitGuideButtons, moveButtons]
  );

  return (
    <div className={`relative ${className}`}>
      <Swiper
        ref={ref}
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween}
        slidesOffsetBefore={slidesOffsetBefore}
        slidesOffsetAfter={slidesOffsetAfter}
        wrapperClass={wrapperClass}
        centeredSlides={centeredSlides}
        autoplay={autoplay}
        breakpoints={breakpoints}
        loop={loop}
        freeMode={freeMode}
        mousewheel={mousewheel}
        observer
        initialSlide={initialSlide}
        updateOnWindowResize
        scrollbar={scrollbar}
        threshold={threshold}
        slideToClickedSlide={slideToClickedSlide}
      >
        {children}
      </Swiper>
      {!hideButtons && (
        <>
          {!isBeginning && (
            <SwiperButton className="left-[18px] md:left-[16px]" onClick={goPrev}>
              <Arrow direction="left" />
            </SwiperButton>
          )}
          {!isEnd && (
            <SwiperButton className="right-[18px] md:right-[16px]" onClick={goNext}>
              <Arrow direction="right" />
            </SwiperButton>
          )}
        </>
      )}
      {showBullets && (
        <div className="mt-[2px] flex h-[4px] justify-center">
          {children.map((_, idx) => (
            <span
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              className={`mx-[3px] block h-[4px] w-[4px] rounded-[50%] ${
                activeIdx === idx ? 'bg-blue' : 'bg-[#EDEDED]'
              }`}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ManipulatingSwiper;
